import React from 'react';
import { Button, Flex, Popconfirm } from 'antd';
import { CloseCircleFilled, DeleteFilled } from '@ant-design/icons';
import { useBasicTable } from '../../../contexts/BasicTableContext';

export const DeleteWithConfirmationButton = () => {
  const {
    totalItems,
    hasSelected,
    selectedRowKeys,
    handleDeleteSelected,
    handleClearSelected,
  } = useBasicTable();

  return (
    <Flex
      justify="space-between"
      align="center"
      wrap="wrap"
      style={{ marginBottom: 13 }}>
      <span style={{ marginLeft: 8 }}>
        {hasSelected && (
          <>
            {`${selectedRowKeys?.length}/${totalItems} выбранные элементы`}
            <Button
              type="text"
              size="small"
              shape="circle"
              onClick={handleClearSelected}
              icon={<CloseCircleFilled style={{ color: '#747678' }} />}
              style={{ marginLeft: 4 }}
            />
          </>
        )}
      </span>
      <Popconfirm
        placement="topRight"
        disabled={!hasSelected}
        title="Вы действительно хотите удалить выбранные элементы из таблицы?"
        onConfirm={handleDeleteSelected}
        onCancel={handleClearSelected}
        okText="Да"
        cancelText="Отмена">
        <Button
          disabled={!hasSelected}
          type="text"
          size="large"
          shape="circle"
          style={hasSelected ? { color: 'tomato' } : { color: '#b8b8b8' }}
          icon={<DeleteFilled style={{ fontSize: '20px' }} />}></Button>
      </Popconfirm>
    </Flex>
  );
};
