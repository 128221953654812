import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import styles from './DeleteRowButton.module.css';

interface DeleteRowButtonProps<T> {
  record: T;
  deleteRow: (row: T) => void;
}

export const DeleteRowButton = <T,>({
  record,
  deleteRow,
}: DeleteRowButtonProps<T>) => {
  return (
    <Popconfirm
      placement="topLeft"
      title="Вы действительно хотите удалить строку?"
      onConfirm={() => deleteRow(record)}
      okText="Да"
      cancelText="Отмена">
      <Button
        type="text"
        shape="circle"
        className={styles.deleteRowButton}
        icon={<DeleteFilled />}
      />
    </Popconfirm>
  );
};
