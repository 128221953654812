import React, { useCallback, useState } from 'react';
import { Layout } from 'antd';
import { SettingsMenu } from '../../molecules/SettingsMenu/SettingsMenu';
import { SettingsRoutes } from '../../../routes/SettingsRoutes';

export const SettingsContainer = () => {
  const { Sider, Content } = Layout;
  const [selectedMenuKey, setSelectedMenuKey] = useState<string>('');

  const memoizedSetSelectedMenuKey = useCallback((key: string) => {
    setSelectedMenuKey(key);
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', margin: '0 30px' }}>
      <Sider width="25%">
        <SettingsMenu
          selectedMenuKey={selectedMenuKey}
          onMenuKeyChange={memoizedSetSelectedMenuKey}
        />
      </Sider>
      <Content
        style={{ backgroundColor: '#ffffff', padding: '0 20px 50px 20px' }}>
        <SettingsRoutes />
      </Content>
    </Layout>
  );
};
