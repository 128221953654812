import React from 'react';
import { Checkbox, Col, DatePicker, Form, InputNumber, Row } from 'antd';
import { FormViewProps, TypePurchase } from '../../../../types';
import { numberFormatter, numberParser } from '../../../../utils';
import { SimpleSelect } from '../../../atoms/SimpleSelect/SimpleSelect';
import { getAllProduct } from '../../../../api';
import { useLoadingAndSaving } from '../../../../contexts/LoadingAndSavingContext';
import { FormHeader } from '../../../atoms/FormHeader/FormHeader';

export const PurchaseFormView: React.FC<FormViewProps<TypePurchase>> = ({
  form,
  onBlur,
  onCancel,
  initialValues,
}) => {
  const { isSaving } = useLoadingAndSaving();

  return (
    <div className="page-form-style">
      <FormHeader
        header="Закупка"
        title={Form.useWatch('item', form)?.title}
        isSaving={isSaving}
        onCancel={onCancel}
      />
      <Form
        form={form}
        layout="vertical"
        className="form-style"
        initialValues={initialValues}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Товар"
              name="item"
              rules={[{ required: true, message: 'выберите товар' }]}>
              <SimpleSelect
                form={form}
                onBlur={onBlur}
                allowCreation={false}
                fieldName="item"
                placeholder="Выберите товар"
                value={form.getFieldValue('item')}
                getId={item => item.id ?? 0}
                getLabel={item => item.title ?? ''}
                fetchDataList={getAllProduct}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Дата" name="date">
              <DatePicker
                style={{ width: '100%' }}
                format={'DD.MM.YYYY'}
                allowClear={false}
                onBlur={onBlur}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Количество"
              name="amount"
              rules={[{ required: true, message: 'введите количество' }]}>
              <InputNumber
                placeholder="1000"
                style={{ width: '100%' }}
                min={1}
                onBlur={onBlur}
                onPressEnter={onBlur}
                formatter={numberFormatter}
                parser={numberParser}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Цена"
              name="cost"
              rules={[{ required: true, message: 'введите цену' }]}>
              <InputNumber
                placeholder="100"
                style={{ width: '100%' }}
                min={0}
                onBlur={onBlur}
                onPressEnter={onBlur}
                formatter={numberFormatter}
                parser={numberParser}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="paid" valuePropName="checked">
              <Checkbox onChange={onBlur}>Оплачено</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
