import React from 'react';
import { Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useBasicTable } from '../../../contexts/BasicTableContext';
import { TypeWithId } from '../../../types';
import { useNavigate } from 'react-router-dom';

type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

type BasicTableProps<T> = {
  columns: ColumnsType<TypeWithId<T>>;
  idKey?: string;
  itemPath?: (record: TypeWithId<T>) => string | undefined;
  size?: 'small' | 'middle' | 'large';
  pagination?: false | TablePaginationConfig;
};

export const BasicTable = <T extends {}>({
  columns,
  idKey = 'id',
  itemPath,
  size = 'middle',
  pagination,
}: BasicTableProps<T>) => {
  const {
    data,
    isLoading,
    pagination: defaultPagination,
    rowSelection,
    handleNavigateToForm,
    handleChangeTable,
  } = useBasicTable<TypeWithId<T>>();

  const navigate = useNavigate();

  // Функция для обработки клика по строке
  const handleRowClick = (record: TypeWithId<T> & Record<string, any>) => {
    if (itemPath) {
      const path = itemPath(record);
      if (path) {
        navigate(path);
      }
    } else {
      const idValue = idKey
        .split('.')
        .reduce((obj, key) => obj && obj[key], record);
      handleNavigateToForm?.(Number(idValue));
    }
  };

  // Функция для обработки событий строки
  const getRowProps = (record: TypeWithId<T> & Record<string, any>) => ({
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      const isCheckboxCell = target.closest('.ant-table-selection-column');
      if (!isCheckboxCell) {
        handleRowClick(record);
      }
    },
  });

  // Устанавливаем пагинацию: используем переданный prop или значение по умолчанию
  const tablePagination: false | TablePaginationConfig =
    pagination !== undefined
      ? pagination
      : {
          ...defaultPagination,
          position: ['bottomCenter'] as TablePaginationPosition[],
          totalBoundaryShowSizeChanger: 10,
        };

  return (
    <Table<TypeWithId<T>>
      rowKey="id"
      bordered
      size={size}
      columns={columns}
      dataSource={data}
      loading={isLoading}
      onChange={handleChangeTable}
      rowSelection={rowSelection}
      onRow={getRowProps}
      pagination={tablePagination}
    />
  );
};
