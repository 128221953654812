import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  CLIENT,
  CONTACTS,
  ITEMS,
  MATERIAL,
  PRODUCT,
  PURCHASE,
  PURCHASES,
  SELL,
  SETTINGS,
  SHIPMENT,
  STOCK,
  STOCK_ADJUSTMENT,
} from '../api';
import { ClientFormContainer } from '../components/pages/Client/ClientForm/ClientForm.container';
import { MaterialFormContainer } from '../components/pages/Material/MaterialForm/MaterialForm.container';
import { PurchaseFormContainer } from '../components/pages/Purchase/PurchaseForm/PurchaseForm.container';
import { ProductFormContainer } from '../components/pages/Product/ProductForm/ProductForm.container';
import { StockAdjustmentFormContainer } from '../components/pages/StockAdjustment/StockAdjustmentForm/StockAdjustmentForm.container';
import { LoadingAndSavingProvider } from '../contexts/LoadingAndSavingContext';
import { ShipmentFormContainer } from '../components/pages/Shipment/ShipmentForm/ShipmentForm.container';
import { SettingsContainer } from '../components/pages/Settings/Settings.container';

export const ContentRoutes: React.FC = () => {
  return (
    <LoadingAndSavingProvider>
      <Routes>
        {/*<Route path={`${SUBSCRIPTION}`} element={<PageSubscription />} />*/}
        <Route
          path={`${CONTACTS}${CLIENT}/:id?`}
          element={<ClientFormContainer />}
        />
        <Route
          path={`${PURCHASES}${PURCHASE}/:id?`}
          element={<PurchaseFormContainer />}
        />
        <Route
          path={`${SELL}${SHIPMENT}/:id?`}
          element={<ShipmentFormContainer />}
        />
        <Route
          path={`${ITEMS}${PRODUCT}/:id?`}
          element={<ProductFormContainer />}
        />
        <Route
          path={`${ITEMS}${MATERIAL}/:id?`}
          element={<MaterialFormContainer />}
        />
        <Route
          path={`${STOCK}${STOCK_ADJUSTMENT}/:id?`}
          element={<StockAdjustmentFormContainer />}
        />
        <Route path={`${SETTINGS}/*`} element={<SettingsContainer />} />
      </Routes>
    </LoadingAndSavingProvider>
  );
};
