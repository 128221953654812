import React, { useEffect, useRef, useState } from 'react';
import { GetRef, Input } from 'antd';

// Тип для ссылки на Input
type InputRef = GetRef<typeof Input>;

interface EditableInputProps<T> {
  children: React.ReactNode;
  dataIndex: keyof T;
  record: T;
  save: (record: T) => void;
  editable: boolean;
  placeholder: string;
  initialEditing?: boolean;
}

export const EditableInput = <T,>({
  children,
  dataIndex,
  record,
  save,
  editable,
  placeholder,
  initialEditing = false,
}: EditableInputProps<T>) => {
  const [editing, setEditing] = useState(initialEditing);
  const [value, setValue] = useState<string>(
    (record[dataIndex] as string) || '',
  );
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    // Если initialEditing изменился, обновляем состояние editing
    setEditing(initialEditing);
  }, [initialEditing]);

  if (!editable) {
    // Если редактирование запрещено, просто отображаем текст без возможности редактирования
    return (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }}>
        {children}
      </div>
    );
  }

  // Переключение режима редактирования
  const toggleEdit = () => {
    setEditing(!editing);
    setValue((record[dataIndex] as string) || '');
  };

  // Сохранение изменений
  const onSave = () => {
    setEditing(false);
    save({ ...record, [dataIndex]: value });
  };

  // Выделение текста при фокусе
  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  // Обработка изменений ввода
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  if (editing) {
    return (
      <Input
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onSave}
        onPressEnter={onSave}
        onFocus={onFocus}
      />
    );
  }

  return (
    <div
      className="editable-cell-value-wrap"
      style={{ paddingRight: 24 }}
      onClick={toggleEdit}
      onKeyDown={event => {
        if (event.key === 'Enter' || event.key === ' ') {
          toggleEdit();
        }
      }}
      tabIndex={0}
      role="button">
      {children}
    </div>
  );
};
