import React, { useCallback, useEffect } from 'react';
import {
  CLIENT,
  CONTACTS,
  deleteClientById,
  getAllClient,
} from '../../../../api';
import { TypeClient } from '../../../../types';
import { ClientTableView } from './ClientTable.view';
import usePagination from '../../../../hooks/usePagination';
import { useDataListLoader } from '../../../../hooks';
import useNavigateToPath from '../../../../hooks/useNavigateToPath';
import useRowSelection from '../../../../hooks/useRowSelection';
import { BasicTableProvider } from '../../../../contexts/BasicTableContext';
import * as Sentry from '@sentry/react';

export const ClientTableContainer = () => {
  // Хук для загрузки и получения всех данных
  const { isLoading, dataList, getDataList } =
    useDataListLoader<TypeClient[]>();

  // Хука для пагинации
  const { pagination, handleChangeTable } = usePagination(10);

  // Хук для навигации
  const handleNavigateToForm = useNavigateToPath(`${CONTACTS}${CLIENT}`);

  // Хук для выбора строк
  const {
    hasSelected,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    handleClearSelected,
  } = useRowSelection<TypeClient>();

  // Функция массового удаления
  const handleDeleteSelected = useCallback(() => {
    (async () => {
      try {
        // Проходим по всем выбранным ключам и удаляем соответствующие записи
        await Promise.all(
          selectedRowKeys.map(key => deleteClientById(Number(key))),
        );
        await getDataList(getAllClient);
      } catch (error) {
        Sentry.captureException(
          new Error('Ошибка при удалении записи: ' + error),
        );
      } finally {
        setSelectedRowKeys([]);
      }
    })();
  }, [selectedRowKeys, getDataList, setSelectedRowKeys]);

  useEffect(() => {
    getDataList(getAllClient).catch((error: unknown) => {
      Sentry.captureException(
        new Error('Ошибка при получении данных: ' + error),
      );
    });
  }, [getDataList]);

  return (
    <BasicTableProvider<TypeClient>
      value={{
        data: dataList,
        isLoading,
        pagination,
        selectedRowKeys,
        hasSelected,
        rowSelection,
        handleNavigateToForm,
        handleChangeTable,
        handleDeleteSelected,
        handleClearSelected,
        totalItems: dataList?.length,
      }}>
      <ClientTableView />
    </BasicTableProvider>
  );
};
