import { TypeApiResponse, TypeUnit } from '../types';
import { UNIT } from './apiEndpoints';
import { api } from './api';

// Получить список всех единиц измерения
export async function getAllUnit(): Promise<TypeUnit[]> {
  const response = await api.get(UNIT);
  return response.data;
}

// Добавить новую единицу измерения
export function createUnit(data: TypeUnit): Promise<TypeApiResponse> {
  return api.post(UNIT, data);
}

// Удалить единицу измерения по id
export function deleteUnitById(id: number): Promise<TypeApiResponse> {
  return api.delete(`${UNIT}/${id}`);
}
