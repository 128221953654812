import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useSimpleSelectActions } from '../../../../../hooks';
import { SimpleSelect } from '../../../../atoms/SimpleSelect/SimpleSelect';
import { getAllCategory, getAllUnit } from '../../../../../api';

interface MaterialFormProps {
  materialForm: any;
  onBlur?: () => void;
  onTitleChange: (title: string) => void;
}

export const MaterialMainForm: React.FC<MaterialFormProps> = ({
  materialForm,
  onBlur,
  onTitleChange,
}) => {
  const { onCreateNewCategory, onCreateNewUnit } = useSimpleSelectActions();

  return (
    <Form form={materialForm} layout="vertical" className="form-style">
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Название"
            name="title"
            rules={[{ required: true, message: 'Введите название материала' }]}>
            <Input
              placeholder="Введите название"
              onBlur={onBlur}
              onPressEnter={onBlur}
              onChange={event => onTitleChange(event.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Единица измерения" name="unit">
            <SimpleSelect
              form={materialForm}
              onBlur={onBlur}
              allowCreation={true}
              fieldName="unit"
              placeholder="Выберите единицу измерения"
              value={materialForm.getFieldValue('unit')}
              getId={item => item.id ?? 0}
              getLabel={item => item.name ?? ''}
              fetchDataList={getAllUnit}
              onCreateNew={onCreateNewUnit}
              disabled={!materialForm.getFieldValue('title')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Категория" name="category">
            <SimpleSelect
              form={materialForm}
              onBlur={onBlur}
              allowCreation={true}
              fieldName="category"
              placeholder="Выберите категорию"
              value={materialForm.getFieldValue('category')}
              getId={item => item.id ?? 0}
              getLabel={item => item.title ?? ''}
              fetchDataList={getAllCategory}
              onCreateNew={onCreateNewCategory}
              disabled={!materialForm.getFieldValue('title')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
