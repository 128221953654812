import React, { useCallback, useEffect } from 'react';
import {
  deleteShipmentById,
  getAllShipment,
  SELL,
  SHIPMENT,
} from '../../../../api';
import { TypeClient, TypeShipment } from '../../../../types';
import { ShipmentTableView } from './ShipmentTable.view';
import { useDataListLoader } from '../../../../hooks';
import usePagination from '../../../../hooks/usePagination';
import useNavigateToPath from '../../../../hooks/useNavigateToPath';
import useRowSelection from '../../../../hooks/useRowSelection';
import * as Sentry from '@sentry/react';
import { BasicTableProvider } from '../../../../contexts/BasicTableContext';

export const ShipmentTableContainer = () => {
  // Хук для загрузки и получения всех данных
  const { isLoading, dataList, getDataList } =
    useDataListLoader<TypeShipment[]>();

  // Хука для пагинации
  const { pagination, handleChangeTable } = usePagination(10);

  // Хук для навигации
  const handleNavigateToForm = useNavigateToPath(`${SELL}${SHIPMENT}`);

  // Хук для выбора строк
  const {
    hasSelected,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    handleClearSelected,
  } = useRowSelection<TypeShipment>();

  // Функция массового удаления
  const handleDeleteSelected = useCallback(() => {
    (async () => {
      try {
        // Проходим по всем выбранным ключам и удаляем соответствующие записи
        await Promise.all(
          selectedRowKeys.map(key => deleteShipmentById(Number(key))),
        );
        await getDataList(getAllShipment);
      } catch (error) {
        Sentry.captureException(
          new Error('Ошибка при удалении отгрузки: ' + error),
        );
      } finally {
        setSelectedRowKeys([]);
      }
    })();
  }, [selectedRowKeys, getDataList, setSelectedRowKeys]);

  useEffect(() => {
    getDataList(getAllShipment).catch((error: unknown) => {
      Sentry.captureException(
        new Error('Ошибка при получении данных отгрузок: ' + error),
      );
    });
  }, [getDataList]);

  return (
    <BasicTableProvider<TypeClient>
      value={{
        data: dataList,
        isLoading,
        pagination,
        selectedRowKeys,
        hasSelected,
        rowSelection,
        handleNavigateToForm,
        handleChangeTable,
        handleDeleteSelected,
        handleClearSelected,
        totalItems: dataList?.length,
      }}>
      <ShipmentTableView />
    </BasicTableProvider>
  );
};
