import React from 'react';
import { Button, Popconfirm } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TypeCategory, TypeWithId } from '../../../../types';
import { DeleteFilled } from '@ant-design/icons';
import styles from '../../../atoms/DeleteRowButton/DeleteRowButton.module.css';
import { BasicTable } from '../../../molecules/BasicTable/BasicTable';
import { useBasicTable } from '../../../../contexts/BasicTableContext';

export const CategoryTableView = () => {
  const { onDeleteRow } = useBasicTable<TypeCategory>();
  const columns: ColumnsType<TypeWithId<TypeCategory>> = [
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: '3%',
      align: 'center',
      render: (id: number) => (
        <span onClick={event => event.stopPropagation()}>
          <Popconfirm
            placement="topRight"
            title="Вы действительно хотите удалить эту категорию?"
            onConfirm={() => onDeleteRow?.(id)}
            okText="Да"
            cancelText="Отмена">
            <Button
              type="text"
              shape="circle"
              className={styles.deleteRowButton}
              icon={<DeleteFilled />}
            />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div style={{ width: '50%' }}>
      <BasicTable columns={columns} size="small" pagination={false} />
    </div>
  );
};