import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TypeUnit } from '../../../../types';
import { DeleteRowButton } from '../../../atoms/DeleteRowButton/DeleteRowButton';
import { AddNewRowButton } from '../../../atoms/AddNewRowButton/AddNewRowButton';
import { EditableInput } from '../../../molecules/EditableInput/EditableInput';

type ColumnTypes = ColumnsType<TypeUnit>;

interface UnitTableViewProps {
  dataSource: TypeUnit[];
  addNewRow: () => void;
  deleteRow: (row: TypeUnit) => Promise<void>;
  saveRow: (row: TypeUnit) => Promise<void>;
  editingKey: React.Key | null;
}

export const UnitTableView: React.FC<UnitTableViewProps> = ({
  dataSource,
  addNewRow,
  deleteRow,
  saveRow,
  editingKey,
}) => {
  const columns: ColumnTypes = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: false,
      sorter: (a, b) => ((a.name ?? '') < (b.name ?? '') ? -1 : 1),
      render: (_, record) => (
        <EditableInput
          dataIndex="name"
          placeholder="Введите имя"
          record={record}
          save={saveRow}
          editable={!record.id}
          initialEditing={record.key === editingKey}>
          {record.name}
        </EditableInput>
      ),
    },
    {
      title: '',
      dataIndex: 'delete',
      width: '3%',
      align: 'center',
      render: (_, record) => (
        <DeleteRowButton record={record} deleteRow={deleteRow} />
      ),
    },
  ];

  return (
    <div style={{ width: '50%' }}>
      <Table<TypeUnit>
        bordered
        size="small"
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
      <AddNewRowButton onClick={addNewRow} />
    </div>
  );
};
