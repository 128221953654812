/*Для таблицы*/

// Функция отображает числовое значение с двумя знаками после запятой 1 055,5
export const renderNumber = (value: number | null | undefined) => {
  return (value ?? 0).toLocaleString('ru-RU', {
    maximumFractionDigits: 2, // максимальное количество знаков после запятой
  });
};

// Функция отображает числовое значение в формате валюты с двумя знаками после запятой и символом валюты 1 055,50 ₽
export const renderAsRuble = (value: number | null | undefined) => {
  return (value ?? 0).toLocaleString('ru-RU', {
    style: 'currency', // показывает валюту рубли
    currency: 'RUB', // без style не показывает валюту!
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/*Для формы*/

// Функция форматирования числа в значение с пробелами и запятой
export const numberFormatter = (value: number | string | undefined): string => {
  if (value === undefined || value === null || value === '') return '';
  const numberValue =
    typeof value === 'string'
      ? Number(value.replace(/\s/g, '').replace(',', '.'))
      : value;
  if (isNaN(numberValue)) return '';
  return numberValue.toLocaleString('ru-RU');
};

// Функция преобразования значения обратно в число или пустую строку
export const numberParser = (value?: string): string | number => {
  if (!value) return '';
  const parsedValue = value.replace(/\s/g, '').replace(',', '.');
  const numberValue = parseFloat(parsedValue);
  return isNaN(numberValue) ? '' : numberValue;
};
