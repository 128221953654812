import React from 'react';
import { Col, DatePicker, Form, Row } from 'antd';
import { FormViewProps, TypeShipment } from '../../../../types';
import { useLoadingAndSaving } from '../../../../contexts/LoadingAndSavingContext';
import { FormHeader } from '../../../atoms/FormHeader/FormHeader';
import { getAllClient } from '../../../../api';
import { SimpleSelect } from '../../../atoms/SimpleSelect/SimpleSelect';

export const ShipmentFormView: React.FC<FormViewProps<TypeShipment>> = ({
  form,
  onBlur,
  onCancel,
  initialValues,
}) => {
  const { isSaving } = useLoadingAndSaving();

  return (
    <div className="page-form-style">
      <FormHeader
        header="Отгрузка"
        title={Form.useWatch('client', form)?.title}
        isSaving={isSaving}
        onCancel={onCancel}
      />
      <Form
        form={form}
        layout="vertical"
        className="form-style"
        initialValues={initialValues}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Клиент"
              name="client"
              rules={[{ required: true, message: 'выберите клиента' }]}>
              <SimpleSelect
                form={form}
                onBlur={onBlur}
                allowCreation={false}
                fieldName="client"
                placeholder="Выберите клиента"
                value={form.getFieldValue('client')}
                getId={client => client.id ?? 0}
                getLabel={client => client.title ?? ''}
                fetchDataList={getAllClient}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Дата" name="date">
              <DatePicker
                style={{ width: '100%' }}
                format={'DD.MM.YYYY'}
                allowClear={false}
                onBlur={onBlur}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
