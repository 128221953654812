import React, { useEffect } from 'react';
import { Flex, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { CATEGORIES, SETTINGS, UNITS } from '../../../api';
import { RightOutlined } from '@ant-design/icons';

interface SettingsMenuItem {
  key: string;
  label: string;
  linkPath: string;
  icon?: React.ReactNode;
}

const menuItems: SettingsMenuItem[] = [
  {
    key: '01',
    label: 'Единицы измерения',
    linkPath: `${SETTINGS}${UNITS}`,
    icon: <RightOutlined />,
  },
  {
    key: '02',
    label: 'Категории',
    linkPath: `${SETTINGS}${CATEGORIES}`,
    icon: <RightOutlined />,
  },
  // {
  //   key: '02',
  //   label: 'Операции',
  //   linkPath: `${SETTINGS}${OPERATIONS}`,
  //   icon: <RightOutlined />,
  // },
];

const items = menuItems.map(item => ({
  key: item.key,
  label: (
    <Link to={item.linkPath}>
      <Flex justify="space-between">
        {item.label} {item.icon}
      </Flex>
    </Link>
  ),
}));

interface SettingsMenuProps {
  onMenuKeyChange: (key: string) => void;
  selectedMenuKey: string;
}

export const SettingsMenu: React.FC<SettingsMenuProps> = ({
  onMenuKeyChange,
  selectedMenuKey,
}) => {
  const location = useLocation();

  useEffect(() => {
    const currentItem = menuItems.find(item =>
      location.pathname.startsWith(item.linkPath),
    );
    if (currentItem && currentItem.key !== selectedMenuKey) {
      onMenuKeyChange(currentItem.key);
    }
  }, [location.pathname, onMenuKeyChange, selectedMenuKey]);

  return (
    <Menu
      mode="inline"
      selectedKeys={[selectedMenuKey]}
      style={{ height: '100%', width: '100%', padding: '20px 0' }}
      items={items}
    />
  );
};
