import React, { useCallback, useEffect, useState } from 'react';
import { FloatButton, Typography } from 'antd';
import { createUnit, deleteUnitById, getAllUnit } from '../../../../api';
import { TypeUnit } from '../../../../types';
import { UnitTableView } from './UnitTable.view';
import * as Sentry from '@sentry/react';
import { LoadingSpinner } from '../../../atoms/LoadingSpinner/LoadingSpinner';

export const UnitTableContainer = () => {
  const [dataSource, setDataSource] = useState<TypeUnit[]>([]);
  const [editingKey, setEditingKey] = useState<React.Key | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Функция обновления таблицы
  const updateTable = useCallback(() => {
    setIsLoading(true);
    getAllUnit()
      .then(data => {
        if (data) {
          const newDataSource = data.map((item, index) => ({
            ...item,
            key: index.toString(),
          }));
          setDataSource(newDataSource);
        }
      })
      .catch(error =>
        Sentry.captureException(
          new Error('Ошибка при получении единиц измерения: ' + error),
        ),
      )
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);

  // Функция добавления новой строки
  const addNewRow = () => {
    const newKey = Date.now().toString();
    const newRow: TypeUnit = {
      key: newKey,
      name: '',
    };

    setDataSource(prevDataSource => [...prevDataSource, newRow]);
    setEditingKey(newKey);
  };

  // Функция сохранения строки
  const saveRow = useCallback(
    async (row: TypeUnit) => {
      try {
        // Создание новой единицы
        const response = await createUnit({ name: row.name });
        if (response?.data?.id) {
          row.id = response.data.id;
        }

        // Обновление состояния dataSource
        setDataSource(prevDataSource => {
          const index = prevDataSource.findIndex(item => item.key === row.key);
          const newDataSource = [...prevDataSource];
          if (index > -1) {
            newDataSource.splice(index, 1, row);
          } else {
            newDataSource.push(row);
          }
          return newDataSource;
        });

        setEditingKey(null);
      } catch (error) {
        Sentry.captureException(
          new Error('Ошибка при сохранении единицы измерения: ' + error),
        );
      }
    },
    [setDataSource, setEditingKey],
  );

  // Функция удаления строки
  const deleteRow = useCallback(
    async (row: TypeUnit) => {
      if (row.id) {
        try {
          await deleteUnitById(row.id);
          // Удаляем строку из dataSource только после успешного удаления на сервере
          setDataSource(prevDataSource =>
            prevDataSource.filter(item => item.key !== row.key),
          );
        } catch (error) {
          Sentry.captureException(
            new Error('Ошибка при удалении единицы измерения: ' + error),
          );
        }
      }
    },
    [setDataSource],
  );

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Typography.Title level={4}>Единицы измерения</Typography.Title>
          <UnitTableView
            dataSource={dataSource}
            addNewRow={addNewRow}
            deleteRow={deleteRow}
            saveRow={saveRow}
            editingKey={editingKey}
          />
          <FloatButton.BackTop />
        </>
      )}
    </div>
  );
};
