import React, { useCallback } from 'react';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { loginUser, SELL, SHIPMENTS } from '../../../api';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import { RegistrationModal } from '../../molecules/RegistrationModal/RegistrationModal';
import { useRegistration } from '../../../hooks';
import headerLogoLightMontserrat from 'assets/images/header_logo_light_montserrat.png';
import * as Sentry from '@sentry/react';

export const Login: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // Хук состояния модального окна и регистрация нового пользователя
  const { isModalOpen, setIsModalOpen, handleCreateNewUser } =
    useRegistration();

  // Скрыть показать пароль
  const iconRender = useCallback(
    (visible: boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />),
    [],
  );

  // Авторизация пользователя
  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        loginUser(values)
          .then(response => {
            if (response?.jwt) {
              navigate(`${SELL + SHIPMENTS}`);
            }
          })
          .catch(error =>
            Sentry.captureException(
              new Error('Ошибка при авторизации: ' + error),
            ),
          );
      })
      .catch(error => {
        Sentry.captureException(new Error('Ошибка валидации: ' + error));
      });
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginHeader}>
        <a href="https://zolotenkov.ru" rel="noopener noreferrer">
          <img
            src={headerLogoLightMontserrat}
            alt="logo"
            className={styles.loginLogo}
          />
        </a>
      </div>
      <Form form={form} className={styles.loginForm} onFinish={onFinish}>
        <Form.Item>
          <div className={styles.loginTitle}>Вход</div>
        </Form.Item>
        <Form.Item
          name="login"
          rules={[{ required: true, message: 'введите вашу почту' }]}>
          <Input
            size="large"
            prefix={<MailOutlined className={styles.loginInputIcon} />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'введите ваш пароль' }]}>
          <Input.Password
            size="large"
            prefix={<LockOutlined className={styles.loginInputIcon} />}
            type="password"
            placeholder="Пароль"
            visibilityToggle
            iconRender={iconRender}
          />
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className={styles.loginSubmitButton}>
            Войти
          </Button>
        </Form.Item>
        <div className={styles.loginFormField}>
          <Form.Item>
            {/* Когда добавим ссылку в href, удалить исключение! */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              rel="noopener noreferrer"
              className={styles.loginForgotPassword}>
              Забыли пароль?
            </a>
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              type="link"
              className={styles.loginSubmitButton}
              onClick={() => setIsModalOpen(true)}>
              Регистрация
            </Button>
          </Form.Item>
        </div>
        <div className={styles.loginRegistrationTerms}>
          Используя сервис Zolotenkov, вы принимаете условия
          <a href="/oferta.pdf" target="_blank" rel="noopener noreferrer">
            {' '}
            договора-оферты
          </a>
        </div>
      </Form>
      <div className={styles.loginFooter}>
        <p className={styles.loginFooterContact}>Связаться с нами:</p>
        <p className={styles.loginFooterEmail}>Email: svetlana@zolotenkov.ru</p>
        <p className={styles.loginFooterCopyright}>© Zolotenkov 2022-2024</p>
      </div>
      <RegistrationModal
        isOpen={isModalOpen}
        createItem={handleCreateNewUser}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
};
