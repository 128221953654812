import React from 'react';
import { FloatButton } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TypeClient, TypeShipment, TypeWithId } from '../../../../types';
import dayjs from 'dayjs';
import { DeleteWithConfirmationButton } from '../../../atoms/DeleteWithConfirmationButton/DeleteWithConfirmationButton';
import { AddButton } from '../../../atoms/AddButton/AddButton';
import { BasicTable } from '../../../molecules/BasicTable/BasicTable';

export const ShipmentTableView = () => {
  const columns: ColumnsType<TypeWithId<TypeShipment>> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      showSorterTooltip: false,
      sorter: (a, b) => ((a.date ?? '') < (b.date ?? '') ? -1 : 1),
      render: (date: any) =>
        date ? <div>{dayjs(date).format('DD.MM.YYYY')}</div> : null,
    },
    {
      title: 'Клиент',
      dataIndex: 'client',
      key: 'client',
      showSorterTooltip: false,
      sorter: (a, b) =>
        (a.client?.title ?? '') < (b.client?.title ?? '') ? -1 : 1,
      render: (client: TypeClient) =>
        client !== null ? <div>{client.title}</div> : null,
    },
  ];

  return (
    <div>
      <AddButton />
      <DeleteWithConfirmationButton />
      <BasicTable columns={columns} />
      <FloatButton.BackTop />
    </div>
  );
};
