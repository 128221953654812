import { TypeApiResponse, TypeShipment } from '../types';
import { SHIPMENT } from './apiEndpoints';
import { api } from './api';

// Получить список всех отгрузок
export async function getAllShipment(): Promise<TypeShipment[]> {
  const response = await api.get(SHIPMENT);
  return response.data;
}

// Получить данные отгрузки по id
export async function getShipmentById(
  id: number,
): Promise<TypeShipment | undefined> {
  const response = await api.get(`${SHIPMENT}/${id}`);
  return response.data;
}

// Добавить новую отгрузку
export function createShipment(data: TypeShipment): Promise<TypeApiResponse> {
  return api.post(SHIPMENT, data);
}

// Удалить отгрузку по id
export function deleteShipmentById(id: number): Promise<TypeApiResponse> {
  return api.delete(`${SHIPMENT}/${id}`);
}

// Редактировать отгрузку
export function updateShipment(data: TypeShipment): Promise<TypeApiResponse> {
  return api.put(SHIPMENT, data);
}
