import React from 'react';
import { FormViewProps, TypeMaterial } from '../../../../types';
import { useLoadingAndSaving } from '../../../../contexts/LoadingAndSavingContext';
import { FormHeader } from '../../../atoms/FormHeader/FormHeader';
import { FormRadio } from '../../../atoms/FormRadio/FormRadio';
import { MaterialMainForm } from './main/MaterialMainForm';

export const MaterialFormView: React.FC<FormViewProps<TypeMaterial>> = ({
  form,
  onBlur,
  onCancel,
}) => {
  const { isSaving } = useLoadingAndSaving();

  // Состояние для хранения значения поля title
  const [title, setTitle] = React.useState(form.getFieldValue('title'));

  // Состояние для выбранной опции
  const [selectedOption, setSelectedOption] = React.useState('main');

  // Опции для радио-кнопок
  const radioOptions = [
    { value: 'main', label: 'Главная информация' },
    { value: 'specification', label: 'Используется в спецификации' },
    { value: 'details', label: 'Подробности поставки' },
  ];

  // Содержимое опций
  const renderContent = () => {
    switch (selectedOption) {
      case 'main':
        return (
          <MaterialMainForm
            materialForm={form}
            onBlur={onBlur}
            onTitleChange={setTitle}
          />
        );
      case 'specification':
        return (
          <div className="form-style">
            Страница со спецификацией скоро появится здесь...
          </div>
        );
      case 'details':
        return (
          <div className="form-style">
            Страница с подробностями поставки скоро появится здесь...
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="page-form-style">
      <FormHeader
        header="Материал"
        title={title}
        isSaving={isSaving}
        onCancel={onCancel}
      />
      <FormRadio
        value={selectedOption}
        onChange={setSelectedOption}
        options={radioOptions}
      />
      {renderContent()}
    </div>
  );
};
