import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { FormViewProps, TypeClient } from '../../../../types';
import { useLoadingAndSaving } from '../../../../contexts/LoadingAndSavingContext';
import { FormHeader } from '../../../atoms/FormHeader/FormHeader';

export const ClientFormView: React.FC<FormViewProps<TypeClient>> = ({
  form,
  onBlur,
  onCancel,
  initialValues,
}) => {
  const { isSaving } = useLoadingAndSaving();

  return (
    <div className="page-form-style">
      <FormHeader
        header="Клиент"
        title={Form.useWatch('title', form)}
        isSaving={isSaving}
        onCancel={onCancel}
      />
      <Form
        form={form}
        layout="vertical"
        className="form-style"
        initialValues={initialValues}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Название"
              name="title"
              rules={[{ required: true, message: 'введите название клиента' }]}>
              <Input
                placeholder="Введите название клиента"
                onBlur={onBlur}
                onPressEnter={onBlur}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};