import React from 'react';
import { Button, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useBasicTable } from '../../../contexts/BasicTableContext';

export const AddButton = () => {
  const { handleNavigateToForm } = useBasicTable();

  return (
    <Flex
      justify="flex-end"
      style={{
        position: 'absolute',
        top: '-8px',
        right: '0',
      }}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => handleNavigateToForm?.()}>
        Добавить
      </Button>
    </Flex>
  );
};
