import React from 'react';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { getAllCategory } from '../../../../api';
import { SimpleSelect } from '../../../atoms/SimpleSelect/SimpleSelect';
import { TypeCategory } from '../../../../types';

interface CategoryFormProps {
  categoryForm: FormInstance;
  initialValues?: TypeCategory;
}

export const CategoryForm: React.FC<CategoryFormProps> = ({
  categoryForm,
  initialValues,
}) => {
  return (
    <Form
      form={categoryForm}
      layout="vertical"
      className="form-style"
      initialValues={initialValues}>
      <Form.Item
        label="Название"
        name="title"
        rules={[{ required: true, message: 'введите название категории' }]}>
        <Input placeholder="Название категории" />
      </Form.Item>
      <Form.Item label="Родительская категория" name="parent">
        <SimpleSelect
          form={categoryForm}
          allowCreation={false}
          fieldName="parent"
          placeholder="Выберите родительскую категорию"
          value={categoryForm.getFieldValue('parent')}
          getId={item => item.id ?? 0}
          getLabel={item => item.title ?? ''}
          fetchDataList={getAllCategory}
          allowClear={true}
        />
      </Form.Item>
    </Form>
  );
};