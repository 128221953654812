import { TypeApiResponse, TypeClient } from '../types';
import { CLIENT } from './apiEndpoints';
import { api } from './api';

// Получить список всех клиентов
export async function getAllClient(): Promise<TypeClient[]> {
  const response = await api.get(CLIENT);
  return response.data;
}

// Получить данные клиента по id
export async function getClientById(
  id: number,
): Promise<TypeClient | undefined> {
  const response = await api.get(`${CLIENT}/${id}`);
  return response.data;
}

// Добавить нового клиента
export function createClient(data: TypeClient): Promise<TypeApiResponse> {
  return api.post(CLIENT, data);
}

// Удалить клиента по id
export function deleteClientById(id: number): Promise<TypeApiResponse> {
  return api.delete(`${CLIENT}/${id}`);
}

// Редактировать клиента
export function updateClient(data: TypeClient): Promise<TypeApiResponse> {
  return api.put(CLIENT, data);
}
