import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CATEGORIES, OPERATIONS, UNITS } from '../api';

import { UnitTableContainer } from '../components/pages/Unit/UnitTable/UnitTable.container';
import { PageOperation } from '../components/pages/PageOperation/PageOperation';
import { CategoryTableContainer } from '../components/pages/Category/CategoryTable/CategoryTable.container';

export const SettingsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={UNITS} element={<UnitTableContainer />} />
      <Route path={CATEGORIES} element={<CategoryTableContainer />} />
      <Route path={OPERATIONS} element={<PageOperation />} />
    </Routes>
  );
};
