import React, { useCallback, useEffect, useState } from 'react';
import { FloatButton, Form, Typography } from 'antd';
import {
  createCategory,
  deleteCategoryById,
  getCategoryById,
  getCategoryTree,
  updateCategory,
} from '../../../../api';
import { CategoryTableView } from './CategoryTable.view';
import { TypeCategory } from '../../../../types';
import * as Sentry from '@sentry/react';
import { LoadingSpinner } from '../../../atoms/LoadingSpinner/LoadingSpinner';
import { AddNewRowButton } from '../../../atoms/AddNewRowButton/AddNewRowButton';
import { FormModal } from '../../../atoms/FormModal/FormModal';
import { CategoryForm } from '../CategoryForm/CategoryForm';
import { BasicTableProvider } from '../../../../contexts/BasicTableContext';

export const CategoryTableContainer: React.FC = () => {
  const [dataSource, setDataSource] = useState<TypeCategory[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState<TypeCategory>();

  const [categoryForm] = Form.useForm();

  // Рекурсивная функция для удаления пустых дочерних элементов
  const removeEmptyChildren = useCallback(
    (category: TypeCategory): TypeCategory => {
      if (category.children && category.children.length === 0) {
        const { children, ...rest } = category;
        return rest;
      }
      if (category.children) {
        return {
          ...category,
          children: category.children.map(removeEmptyChildren),
        };
      }
      return category;
    },
    [],
  );

  // Функция для обновления таблицы
  const updateTable = useCallback(() => {
    setIsLoading(true);
    getCategoryTree()
      .then(data => {
        const updatedData = data.map(removeEmptyChildren);
        setDataSource(updatedData);
        setIsLoading(false);
      })
      .catch(error =>
        Sentry.captureException(
          new Error(
            'Ошибка при получении данных для таблицы категории: ' + error,
          ),
        ),
      );
  }, [removeEmptyChildren]);

  // Рекурсивная функция для удаления категории по id
  const removeCategoryById = useCallback(
    (categories: TypeCategory[], id: number): TypeCategory[] => {
      return categories
        .filter(category => category.id !== id)
        .map(category => {
          if (category.children && category.children.length > 0) {
            return {
              ...category,
              children: removeCategoryById(category.children, id),
            };
          }
          return category;
        });
    },
    [],
  );

  // Удалить запись из таблицы
  const onDeleteRow = useCallback(
    async (id: number) => {
      try {
        await deleteCategoryById(id);
        setDataSource(prevDataSource => {
          if (!prevDataSource) return prevDataSource;
          return removeCategoryById(prevDataSource, id);
        });
      } catch (error) {
        Sentry.captureException(
          new Error('Ошибка при удалении категории: ' + error),
        );
      }
    },
    [removeCategoryById],
  );

  const openModal = async () => {
    setIsModalVisible(true);
  };

  const onSubmit = useCallback(
    async (values: TypeCategory) => {
      const category: TypeCategory = {
        id: initialValues?.id,
        title: values.title,
        parent: values.parent,
      };
      setIsModalVisible(false);
      categoryForm.resetFields();

      if (initialValues?.id) {
        await updateCategory(category);
      } else {
        await createCategory(category);
      }
      updateTable();
      setInitialValues(undefined);
    },
    [initialValues, categoryForm, updateTable],
  );

  const handleGetCategory = useCallback(
    async (id?: number) => {
      if (id === undefined) return;
      try {
        const data = await getCategoryById(id);
        setInitialValues(data);
        categoryForm.setFieldsValue(data);
        setIsModalVisible(true);
      } catch (error) {
        Sentry.captureException(
          new Error('Ошибка при получении данных категории: ' + error),
        );
      }
    },
    [categoryForm, setInitialValues],
  );

  const onCancel = () => {
    categoryForm.resetFields();
    setIsModalVisible(false);
  };

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Typography.Title level={4}>Категории</Typography.Title>
          <BasicTableProvider<TypeCategory>
            value={{
              data: dataSource,
              isLoading: false,
              onDeleteRow,
              handleNavigateToForm: handleGetCategory,
            }}>
            <CategoryTableView />
          </BasicTableProvider>
          <AddNewRowButton onClick={openModal} />
          <FormModal
            width={'500px'}
            isOpen={isModalVisible}
            onSubmit={onSubmit}
            onCancel={onCancel}
            renderForm={form => <CategoryForm categoryForm={form} />}
            form={categoryForm}
            title="Категория"
          />
          <FloatButton.BackTop />
        </>
      )}
    </div>
  );
};
