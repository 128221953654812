import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styles from './MainMenu.module.css';
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  CarryOutOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {
  ALL,
  CLIENTS,
  CONTACTS,
  ESTIMATED_PRICE,
  ITEMS,
  OPERATION,
  OUTPUT,
  PRODUCTION_TYPE,
  PRODUCTS,
  PURCHASES,
  SELL,
  SETTINGS,
  SHIPMENTS,
  STOCK,
  STOCKS,
  STORAGE_PLACE,
  UNITS,
} from '../../../api';

interface MenuMainItem {
  key: string;
  label: string;
  linkPath: string;
  paths: string[];
  icon: React.ReactNode;
}

const menuItems: MenuMainItem[] = [
  {
    key: '01',
    label: 'Продажи',
    paths: [`${SELL}`],
    linkPath: `${SELL}${SHIPMENTS}`,
    icon: <ShopOutlined style={{ fontSize: '24px' }} />,
  },
  {
    key: '02',
    label: 'Создать',
    paths: [`${OPERATION}`, `${PRODUCTION_TYPE}`, `${OUTPUT}`],
    linkPath: `${OPERATION}`,
    icon: <AppstoreAddOutlined style={{ fontSize: '24px' }} />,
  },
  {
    key: '03',
    label: 'Закупки',
    paths: [`${PURCHASES}`],
    linkPath: `${PURCHASES}${ALL}`,
    icon: <ShoppingCartOutlined style={{ fontSize: '24px' }} />,
  },
  {
    key: '04',
    label: 'Склад',
    paths: [`${STOCKS}`, `${STOCK}`, `${STORAGE_PLACE}`],
    linkPath: `${STOCKS}${ALL}`,
    icon: <AppstoreOutlined style={{ fontSize: '24px' }} />,
  },
  // {
  //   key: '05',
  //   label: (
  //     <div to={`${REPORT}${OPERATION}`} className="menu-item-container">
  //       <BarChartOutlined
  //         className="menu-item-icon"
  //         style={{ fontSize: '24px' }}
  //       />
  //       <div className="menu-item-div">Отчеты</div>
  //     </div>
  //   ),
  // },
  {
    key: '06',
    label: 'Элементы',
    paths: [`${ITEMS}`, `${ESTIMATED_PRICE}`],
    linkPath: `${ITEMS}${PRODUCTS}`,
    icon: <CarryOutOutlined style={{ fontSize: '24px' }} />,
  },
  {
    key: '07',
    label: 'Контакты',
    paths: [`${CONTACTS}`],
    linkPath: `${CONTACTS}${CLIENTS}`,
    icon: <TeamOutlined style={{ fontSize: '24px' }} />,
  },
  {
    key: '08',
    label: 'Настройки',
    paths: [`${SETTINGS}`],
    linkPath: `${SETTINGS}${UNITS}`,
    icon: <SettingOutlined style={{ fontSize: '24px' }} />,
  },
];

const items = menuItems.map(item => ({
  key: item.key,
  label: (
    <Link to={item.linkPath} className={styles.menuItemContainer}>
      {item.icon}
      <div className={styles.menuItemLabel}>{item.label}</div>
    </Link>
  ),
}));

interface MenuMainProps {
  onMenuKeyChange: (key: string) => void;
  selectedMenuKey: string;
}

export const MainMenu: React.FC<MenuMainProps> = ({
  onMenuKeyChange,
  selectedMenuKey,
}) => {
  const location = useLocation();

  // useEffect для обновления selectedMenuKey на основе текущего пути
  useEffect(() => {
    const currentItem = menuItems.find(item =>
      item.paths.some(path => location.pathname.startsWith(path)),
    );
    if (currentItem && currentItem.key !== selectedMenuKey) {
      localStorage.setItem('activeMenuKey', currentItem.key);
      onMenuKeyChange(currentItem.key);
    }
  }, [location.pathname, onMenuKeyChange, selectedMenuKey]);

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      selectedKeys={[selectedMenuKey]}
      items={items}
      style={{ height: '100%', width: '100%' }}
    />
  );
};
