import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/globals.css';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import 'dayjs/locale/ru';
import { AppRoutes } from './routes/AppRoutes';
import * as Sentry from '@sentry/react';

// Определяем, что мы на продакшене
const isProduction =
  process.env.REACT_APP_API_URL === 'https://erp.zolotenkov.ru/api';

// Инициализируем Sentry только на продакшене
if (isProduction) {
  Sentry.init({
    dsn: 'https://d56aab7e207ebfe7a83982fa93032e1c@o4507967052120064.ingest.de.sentry.io/4507967072501840',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: 'production',
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider locale={ru_RU}>
        <AppRoutes />
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
